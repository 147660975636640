import * as React from "react"
import Footer from "../../components/Footer"
import Layout from "../../components/Layout"
import NavBar from "../../components/NavBar"
import ContactForm from "../../components/pages/contact/ContactForm"
import SEO from "../../components/Seo"
import { IPageProps } from "../../typings/gatsbyPage"

const Particuliers: React.FunctionComponent<IPageProps> = ({ location }) => {
  return (
    <Layout>
      <SEO
        title="Contact Particulier"
        description="Formulaire de contact pour les particuliers"
        keywords={["contact", "particulier", "consigne", "zéro déchet"]}
      />
      <NavBar activePage="contact" />
      <ContactForm contactType="particulier" locationHash={location.hash} />
      <Footer />
    </Layout>
  )
}

export default Particuliers
